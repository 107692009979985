import { bindings } from "@formkit/vue";
import { rootClasses } from "./formkit.theme";
import { createProPlugin, autocomplete, toggle, dropdown } from "@formkit/pro";
import {
  createLibraryPlugin,
  text,
  form,
  submit,
  password,
  textarea,
  checkbox,
  number,
} from "@formkit/inputs";
import { createI18nPlugin, en } from "@formkit/i18n";
import { createValidationPlugin } from "@formkit/validation";
import { required, min, confirm } from "@formkit/rules";
import { check, close, down } from "@formkit/icons";
import { createFloatingLabelsPlugin } from "@formkit/addons";
import "@formkit/addons/css/floatingLabels";

const pro = createProPlugin("fk-49e06d5895", {
  autocomplete,
  toggle,
  dropdown,
});

const library = createLibraryPlugin({
  text,
  form,
  submit,
  password,
  textarea,
  checkbox,
  number,
});
const validation = createValidationPlugin({ required, min, confirm });
const i18n = createI18nPlugin({ en });

export default {
  locales: { en },
  locale: "en",
  plugins: [
    createFloatingLabelsPlugin({
      useAsDefault: true,
    }),
    pro,
    library,
    validation,
    i18n,
    bindings,
  ],
  icons: {
    check,
    close,
    down,
  },
  config: {
    rootClasses,
  },
};

import { RouteRecordRaw } from "vue-router";

const watchlistRoutes: Array<RouteRecordRaw> = [
  {
    path: "/watchlist/:id",
    component: () => import("@/views/watchlist/WatchListItem.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/watchlist",
    component: () => import("@/views/watchlist/WatchList.vue"),
  },
];

export default watchlistRoutes;

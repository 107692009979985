import { defineStore } from "pinia";
import { ref } from "vue";

export const useNoticeStore = defineStore("notice", () => {
  const message = ref("");
  const type = ref("");

  function setNotice(messageText: string, messageType = "success") {
    message.value = messageText;
    type.value = messageType;
  }
  function flush() {
    message.value = "";
    type.value = "";
  }

  return { message, type, setNotice, flush };
});

import {
  CapacitorHttp,
  HttpOptions,
  HttpParams,
  HttpResponse,
} from "@capacitor/core";
import { useAuthStore } from "@/stores/useAuthStore";
import {
  FirebaseAuthentication,
  GetIdTokenResult,
} from "@capacitor-firebase/authentication";

export async function useCapacitorHttp(
  path: string,
  method: string,
  opts?: HttpParams | any,
  tries = 1,
): Promise<HttpResponse> {
  if (tries > 3) {
    throw new Error("Too many attempts.");
  }
  const baseOptions: HttpOptions = {
    url: `${import.meta.env.VITE_BACKEND_BASE_URL}/api/${path}`,
    method: method,
    responseType: "json",
  };
  const store = useAuthStore();
  if (method.includes("GET")) {
    if (opts && Object.keys(opts).length !== 0) {
      for (const [key, value] of Object.entries(opts)) {
        opts[String(key)] = String(value);
      }
    }
    baseOptions.params = opts;
  } else {
    baseOptions.data = opts;
  }
  baseOptions.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (store.token) {
    baseOptions.headers = {
      ...baseOptions.headers,
      ...{
        Authorization: `Bearer ${store.token}`,
      },
    };
  }
  const response: HttpResponse = await CapacitorHttp.request(baseOptions);

  if (response.status >= 500) {
    throw new Error("Server error");
  } else if (response.status === 403) {
    throw new Error("Forbidden");
  } else if (response.status === 404) {
    throw new Error("Not Found");
  } else if (response.status === 401) {
    const newToken: GetIdTokenResult = await FirebaseAuthentication.getIdToken({
      forceRefresh: true,
    });
    await store.setFirebaseToken(newToken.token);
    tries++;
    return useCapacitorHttp(path, method, opts, tries);
  }
  return response;
}

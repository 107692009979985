import { defineStore } from "pinia";
import "cordova-plugin-purchase";
import { Capacitor, HttpResponse } from "@capacitor/core";
import { useCapacitorHttp } from "@/composables/useCapacitorHttp";
import { useCrashlytics } from "@/composables/useCrashlytics";
import ProductType = CdvPurchase.ProductType;
import Product = CdvPurchase.Product;
import ErrorCode = CdvPurchase.ErrorCode;
import Transaction = CdvPurchase.Transaction;
import VerifiedReceipt = CdvPurchase.VerifiedReceipt;
import Payload = CdvPurchase.Validator.Response.Payload;
import Body = CdvPurchase.Validator.Request.Body;
import Callback = CdvPurchase.Callback;
import Platform = CdvPurchase.Platform;
import UnverifiedReceipt = CdvPurchase.UnverifiedReceipt;
import Store = CdvPurchase.Store;
import IError = CdvPurchase.IError;
import { useAuthStore } from "@/stores/useAuthStore";
import { Subscription } from "@/interfaces/Subscription";
import { computed, ref } from "vue";

export const useInAppPurchaseStore = defineStore("inAppPurchase", () => {
  const store: Store = CdvPurchase.store;

  const subscriptions = ref<Product[]>([]);
  const purchasedSubscriptions = ref<Subscription[]>([]);
  const isLoading = ref(false);
  const isReady = ref(false);

  async function initStore(): Promise<void> {
    try {
      store.validator = async (receipt: Body, callback: Callback<Payload>) => {
        try {
          const endpoint =
            Capacitor.getPlatform() === "ios" ? "app-store" : "google-play";
          const response: HttpResponse = await useCapacitorHttp(
            `store/${endpoint}`,
            "POST",
            receipt,
          );
          callback({
            ok: true,
            data: {
              id: response.data.productId,
              latest_receipt: true,
              transaction: {
                type: platform.value,
                ...response.data.transaction,
              },
            },
          });
        } catch (e: any) {
          callback({
            ok: false,
            code: ErrorCode.VERIFICATION_FAILED,
          });
        }
      };

      registerProducts();
      registerListeners();

      await store.initialize([platform.value]);
      store.ready(() => {
        subscriptions.value = store.products;
        isReady.value = true;
      });
    } catch (e: any) {
      useCrashlytics(e.message);
    }
  }
  async function update(): Promise<void> {
    try {
      await getPurchasedSubscriptions();
      if (Capacitor.getPlatform() !== "web") {
        await store.update();
      }
    } catch (e: any) {
      useCrashlytics(e.message);
    }
  }
  function registerProducts(): void {
    store.register([
      {
        id: "adfree",
        type: ProductType.PAID_SUBSCRIPTION,
        platform: platform.value,
      },
      {
        id: "analysis_monthly",
        type: ProductType.PAID_SUBSCRIPTION,
        platform: platform.value,
      },
      {
        id: "analysis_yearly",
        type: ProductType.PAID_SUBSCRIPTION,
        platform: platform.value,
      },
      {
        id: "all_in_one_monthly",
        type: ProductType.PAID_SUBSCRIPTION,
        platform: platform.value,
      },
      {
        id: "all_in_one_yearly",
        type: ProductType.PAID_SUBSCRIPTION,
        platform: platform.value,
      },
      {
        id: "rsi_sample",
        type: ProductType.NON_CONSUMABLE,
        platform: platform.value,
      },
      {
        id: "rsi_full",
        type: ProductType.NON_CONSUMABLE,
        platform: platform.value,
      },
    ]);
  }
  function registerListeners(): void {
    store
      .when()
      .approved((transaction: Transaction) => transaction.verify())
      .verified((receipt: VerifiedReceipt) => receipt.finish())
      .finished(async () => {
        await getPurchasedSubscriptions();
        isLoading.value = false;
      })
      .unverified((receipt: UnverifiedReceipt) => {
        useCrashlytics("HTTP ERROR: " + JSON.stringify(receipt));
        isLoading.value = false;
      });

    store.error(function (error: IError) {
      useCrashlytics(`STORE ERROR: ${JSON.stringify(error)}`);
    });
  }
  async function getPurchasedSubscriptions(): Promise<void> {
    const authStore = useAuthStore();
    if (authStore.isLogged) {
      const response: HttpResponse = await useCapacitorHttp(
        `subscription`,
        "GET",
      );
      purchasedSubscriptions.value = response.data;
    }
  }
  function setLoading(isLoadingValue: boolean): void {
    isLoading.value = isLoadingValue;
  }
  async function restore(): Promise<IError | undefined> {
    return await store.restorePurchases();
  }

  const platform = computed(() => {
    if (Capacitor.getPlatform() === "ios") {
      return Platform.APPLE_APPSTORE;
    } else if (Capacitor.getPlatform() === "android") {
      return Platform.GOOGLE_PLAY;
    }
    return Platform.TEST;
  });
  const getSubscriptionById = computed(() => {
    return (id: string) => {
      return subscriptions.value.find((subscription) => subscription.id === id);
    };
  });
  const getPurchasedSubscriptionById = computed(() => {
    return (id: string) => {
      return purchasedSubscriptions.value.find(
        (subscription: Subscription) => subscription.product_id === id,
      );
    };
  });

  return {
    subscriptions,
    purchasedSubscriptions,
    isLoading,
    isReady,
    initStore,
    update,
    setLoading,
    restore,
    getPurchasedSubscriptions,
    platform,
    getSubscriptionById,
    getPurchasedSubscriptionById,
  };
});

<template>
  <div class="my-1">
    <fwb-button
      color="light"
      class="w-full text-center inline-flex items-center justify-center mb-2"
      :loading="loadingStore.loading"
      :disabled="loadingStore.loading"
      @click="loginGoogle"
    >
      <template #prefix>
        <svg
          class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 19"
        >
          <path
            fill-rule="evenodd"
            d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </template>
      {{ $t("user.social_login.login_with_google") }}
    </fwb-button>

    <fwb-button
      color="light"
      class="w-full text-center inline-flex items-center justify-center mb-2"
      :loading="loadingStore.loading"
      :disabled="loadingStore.loading"
      @click="loginApple"
    >
      <template #prefix>
        <svg
          class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 21"
        >
          <path
            d="M14.537 10.625a4.421 4.421 0 0 0 2.684 4.047 10.96 10.96 0 0 1-1.384 2.845c-.834 1.218-1.7 2.432-3.062 2.457-1.339.025-1.769-.794-3.3-.794s-2.009.769-3.275.82c-1.316.049-2.317-1.318-3.158-2.532C1.323 14.984.01 10.451 1.772 7.391a4.9 4.9 0 0 1 4.139-2.507c1.292-.025 2.511.869 3.3.869.789 0 2.271-1.075 3.828-.917A4.67 4.67 0 0 1 16.7 6.82a4.524 4.524 0 0 0-2.161 3.805M12.02 3.193A4.4 4.4 0 0 0 13.06 0a4.482 4.482 0 0 0-2.946 1.516 4.185 4.185 0 0 0-1.061 3.093 3.708 3.708 0 0 0 2.967-1.416Z"
          ></path>
        </svg>
      </template>
      {{ $t("user.social_login.login_with_apple") }}
    </fwb-button>

    <fwb-button
      color="light"
      class="w-full text-center inline-flex items-center justify-center mb-2"
      :loading="loadingStore.loading"
      :disabled="loadingStore.loading"
      @click="loginFacebook"
    >
      <template #prefix>
        <svg
          class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 8 19"
        >
          <path
            fill-rule="evenodd"
            d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </template>
      {{ $t("user.social_login.login_with_facebook") }}
    </fwb-button>

    <fwb-button
      color="light"
      class="w-full text-center inline-flex items-center justify-center mb-2"
      :loading="loadingStore.loading"
      :disabled="loadingStore.loading"
      @click="loginEmail"
    >
      <template #prefix>
        <svg
          class="w-4 h-4 mr-2 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 16"
        >
          <path
            d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"
          ></path>
          <path
            d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"
          ></path>
        </svg>
      </template>
      {{ $t("user.social_login.login_with_email") }}
    </fwb-button>
  </div>
</template>

<script setup lang="ts">
import { useUserLogin } from "@/composables/useUserLogin";
import { useAuthStore } from "@/stores/useAuthStore";
import { useModalStore } from "@/stores/useModalStore";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { FwbButton } from "flowbite-vue";

const { loginWithGoogle, loginWithApple, loginWithFacebook } = useUserLogin();

const emit = defineEmits({
  openLoginWithEmail: false,
});

const store = useAuthStore();
const modalStore = useModalStore();
const loadingStore = useLoadingStore();

const loginGoogle = async () => {
  await loginWithGoogle();
  redirect();
};

const loginApple = async () => {
  await loginWithApple();
  redirect();
};

const loginFacebook = async () => {
  await loginWithFacebook();
  redirect();
};

const loginEmail = () => {
  emit("openLoginWithEmail", true);
};

const redirect = () => {
  if (store.isLogged) {
    modalStore.closeModal("userLogin");
    modalStore.closeModal("userRegister");
  }
};
</script>

import { FirebaseRemoteConfig } from "@capacitor-firebase/remote-config";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useCrashlytics } from "@/composables/useCrashlytics";

export function useRemoteConfig() {
  const loadingStore = useLoadingStore();
  const fetchAndActivate = async () => {
    try {
      await fetchConfig();
      await FirebaseRemoteConfig.fetchAndActivate();
      loadingStore.setActivated(true);
    } catch (e: any) {
      useCrashlytics(e.message);
    }
  };

  const fetchConfig = async () => {
    await FirebaseRemoteConfig.fetchConfig({
      minimumFetchIntervalInSeconds: import.meta.env.PROD ? 600 : 60,
    });
  };

  const getBoolean = async (key: string) => {
    const { value } = await FirebaseRemoteConfig.getBoolean({
      key,
    });
    return value;
  };

  const getNumber = async (key: string) => {
    const { value } = await FirebaseRemoteConfig.getNumber({
      key,
    });
    return value;
  };

  const getString = async (key: string) => {
    const { value } = await FirebaseRemoteConfig.getString({
      key,
    });
    return value;
  };

  return {
    getBoolean,
    getNumber,
    getString,
    fetchConfig,
    fetchAndActivate,
  };
}

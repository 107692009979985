import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";

export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en },
  legacy: false,
  globalInjection: true,
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      chart: {
        month: "short",
        day: "numeric",
      },
      hour: {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
  numberFormats: {
    en: {
      price: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
        roundingPriority: "lessPrecision",
      },
      number: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
        roundingPriority: "lessPrecision",
      },
      percent: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      bigNumber: {
        notation: "compact",
      },
    },
  },
});

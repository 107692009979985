<template>
  <Teleport to="body">
    <fwb-modal
      v-if="isShowModal"
      :not-escapable="notEscapable"
      :persistent="notEscapable"
      modal-classes="dark:bg-gray-800 dark:border-gray-700 bg-white dark:border"
      body-classes="p-0"
      @close="closeModal"
    >
      <template #body>
        <div class="flex flex-col items-center justify-center">
          <div class="w-full md:mt-0 sm:max-w-lg xl:p-0">
            <div class="space-y-4 md:space-y-6 p-6 md:pb-6 md:p-0">
              <h1
                class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
              >
                {{ t("user.login.title") }}
              </h1>
              <fwb-alert icon border type="warning">
                {{ $t("user.checkout.alert") }}
              </fwb-alert>
              <UserSocialLogin
                @open-login-with-email="hideForm = false"
              ></UserSocialLogin>
              <div :class="{ hidden: hideForm, block: !hideForm }">
                <FormKit
                  id="reset-password"
                  type="form"
                  :submit-label="$t('form.submit_button')"
                  :value="values"
                  @submit="login"
                >
                  <FormKit
                    type="text"
                    :label="$t('user.login.email_input')"
                    name="email"
                    validation="required|email"
                  ></FormKit>
                  <FormKit
                    type="password"
                    :label="$t('user.login.password_input')"
                    name="password"
                    validation="required"
                  ></FormKit>
                  <div class="my-4 flex items-center justify-between">
                    <ion-nav-link
                      router-link="/auth/forgot-password"
                      class="hover:cursor-pointer text-sm font-medium text-green-600 hover:underline dark:text-green-500"
                      @click="closeModal"
                    >
                      {{ t("user.login.forgot_password_button") }}
                    </ion-nav-link>
                  </div>
                </FormKit>
              </div>
            </div>
          </div>
        </div>
      </template>
    </fwb-modal>
  </Teleport>
</template>

<script setup lang="ts">
import { IonNavLink } from "@ionic/vue";
import { useUserLogin } from "@/composables/useUserLogin";
import UserSocialLogin from "@/components/user/UserSocialLogin.vue";
import { useI18n } from "vue-i18n";
import { FwbAlert } from "flowbite-vue";
import { computed, ref, watch } from "vue";
import { useModalStore } from "@/stores/useModalStore";
import FwbModal from "@/components/layout/FwbModal.vue";

const { t } = useI18n();
const { loginWithEmailAndPassword } = useUserLogin();
const hideForm = ref(true);

const modalStore = useModalStore();
modalStore.addModal({
  type: "userCheckoutLogin",
  isOpen: false,
  additionalData: {},
});

const isShowModal = computed(() => {
  return modalStore.isOpen("userCheckoutLogin");
});

const notEscapable = computed(() => {
  return modalStore.isEscapable("userCheckoutLogin");
});

const additionalData = computed(() => {
  return modalStore.additionalData("userCheckoutLogin");
});

const values = ref({
  email: additionalData.value?.email ?? "",
  password: "",
});

watch(additionalData, () => {
  values.value = {
    email: additionalData.value?.email ?? "",
    password: "",
  };
});

const closeModal = () => {
  modalStore.closeModal("userCheckoutLogin");
};

const login = async (values: any) => {
  const result = await loginWithEmailAndPassword(values.email, values.password);
  if (result) {
    modalStore.closeModal("userCheckoutLogin");
  }
};
</script>

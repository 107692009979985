import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { ref } from "vue";

export const useLayoutStore = defineStore("layout", () => {
  const isDarkMode = ref(true);
  const bannerHeight = ref(0);

  async function initDarkMode(): Promise<void> {
    const { value } = await Preferences.get({ key: "darkMode" });
    if (value) {
      isDarkMode.value = value === "1";
    }
  }
  async function switchDarkMode(dark: boolean): Promise<void> {
    isDarkMode.value = dark;
    await Preferences.set({ key: "darkMode", value: dark ? "1" : "0" });
  }
  function setBannerHeight(height: number): void {
    bannerHeight.value = height;
  }

  return {
    isDarkMode,
    bannerHeight,
    initDarkMode,
    switchDarkMode,
    setBannerHeight,
  };
});

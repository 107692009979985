import { RouteRecordRaw } from "vue-router";

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    component: () => import("@/views/user/AuthIndex.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/auth/forgot-password",
    component: () => import("@/views/user/UserForgotPassword.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/auth",
    component: () => import("@/views/user/UserResetPassword.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/auth/me",
    component: () => import("@/views/user/UserAccount.vue"),
    meta: { requiresAuth: true },
  },
];

export default authRoutes;

<template>
  <Teleport to="body">
    <fwb-modal
      v-if="isShowModal"
      :not-escapable="notEscapable"
      :persistent="notEscapable"
      modal-classes="dark:bg-gray-800 dark:border-gray-700 bg-white dark:border"
      body-classes="p-0"
      @close="closeModal"
    >
      <template #body>
        <div class="flex flex-col items-center justify-center">
          <div class="w-full md:mt-0 sm:max-w-lg xl:p-0">
            <div class="space-y-4 md:space-y-6 p-6 md:pb-6 md:p-0">
              <h1
                class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
              >
                {{ t("user.login.title") }}
              </h1>
              <fwb-alert icon border type="warning">
                {{ $t("user.login.alert") }}
              </fwb-alert>
              <UserSocialLogin
                @open-login-with-email="hideForm = false"
              ></UserSocialLogin>
              <div :class="{ hidden: hideForm, block: !hideForm }">
                <FormKit
                  id="reset-password"
                  type="form"
                  :submit-label="$t('form.submit_button')"
                  :value="values"
                  @submit="login"
                >
                  <FormKit
                    type="text"
                    :label="$t('user.login.email_input')"
                    name="email"
                    validation="required|email"
                  ></FormKit>
                  <FormKit
                    type="password"
                    :label="$t('user.login.password_input')"
                    name="password"
                    validation="required"
                  ></FormKit>
                  <div class="my-4 flex items-center justify-between">
                    <ion-nav-link
                      router-link="/auth/forgot-password"
                      class="hover:cursor-pointer text-sm font-medium text-green-600 hover:underline dark:text-green-500"
                      @click="closeModal"
                    >
                      {{ t("user.login.forgot_password_button") }}
                    </ion-nav-link>
                  </div>
                </FormKit>
              </div>
              <div>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  {{ t("user.login.create_new_button") }}

                  <a
                    href="#"
                    class="hover:cursor-pointer font-medium text-green-600 hover:underline dark:text-green-500"
                    @click.prevent="switchToRegister"
                  >
                    {{ t("user.login.sign_up_button") }}
                  </a>
                </p>
              </div>
              <div
                v-if="notEscapable"
                class="hover:cursor-pointer font-medium text-green-600 hover:underline dark:text-green-500"
              >
                <fwb-button
                  color="light"
                  class="hover:cursor-pointer inline-flex items-center"
                  @click.prevent="goBack"
                >
                  <template #prefix>
                    <svg
                      class="w-4 h-4 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 12h14M5 12l4-4m-4 4 4 4"
                      ></path>
                    </svg>
                  </template>
                  Go Back
                </fwb-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </fwb-modal>
  </Teleport>
</template>

<script setup lang="ts">
import { IonNavLink, useIonRouter } from "@ionic/vue";
import { useUserLogin } from "@/composables/useUserLogin";
import UserSocialLogin from "@/components/user/UserSocialLogin.vue";
import { useI18n } from "vue-i18n";
import { FwbAlert, FwbButton } from "flowbite-vue";
import { computed, ref } from "vue";
import { useModalStore } from "@/stores/useModalStore";
import FwbModal from "@/components/layout/FwbModal.vue";

const { t } = useI18n();
const { loginWithEmailAndPassword } = useUserLogin();
const hideForm = ref(true);
const values = ref({
  email: "",
  password: "",
});
const router = useIonRouter();

const modalStore = useModalStore();
modalStore.addModal({ type: "userLogin", isOpen: false });

const isShowModal = computed(() => {
  return modalStore.isOpen("userLogin");
});

const notEscapable = computed(() => {
  return modalStore.isEscapable("userLogin");
});

const goBack = () => {
  modalStore.closeModal("userLogin");
  return router.canGoBack() ? router.back() : router.push("/");
};

const closeModal = () => {
  modalStore.closeModal("userLogin");
};

const switchToRegister = () => {
  modalStore.closeModal("userLogin");
  modalStore.openModal("userRegister", notEscapable.value);
};

const login = async (values: any) => {
  const result = await loginWithEmailAndPassword(values.email, values.password);
  if (result) {
    modalStore.closeModal("userLogin");
  }
};
</script>

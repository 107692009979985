import { defineStore } from "pinia";
import { useCapacitorHttp } from "@/composables/useCapacitorHttp";
import { HttpResponse } from "@capacitor/core";
import { User } from "@/interfaces/User";
import { Preferences } from "@capacitor/preferences";
import { Device, DeviceInfo } from "@capacitor/device";
import { useLayoutStore } from "@/stores/useLayoutStore";
import {
  FirebaseAuthentication,
  User as FirebaseUser,
} from "@capacitor-firebase/authentication";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import PromiseLike = CdvPurchase.Internal.PromiseLike;
import { useCrashlytics } from "@/composables/useCrashlytics";
import { computed, ref } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(<User | null>null);
  const pushNotificationToken = ref<string | null>(null);
  const firebaseToken = ref<string | null>(null);
  const registrationType = ref<string | null>(null);

  function setUser(identity: any | null): void {
    user.value = identity;
  }

  async function initToken(): Promise<void> {
    const { value } = await Preferences.get({ key: "token" });
    if (value) {
      firebaseToken.value = value;
    }
  }

  async function logout(): Promise<boolean> {
    try {
      await FirebaseAuthentication.signOut();
      setUser(null);
      await setFirebaseToken(null);
      return true;
    } catch (error: any) {
      useCrashlytics(error.message);
      return false;
    }
  }

  async function setFirebaseToken(token: string | null): Promise<void> {
    firebaseToken.value = token;
    if (!token) {
      await Preferences.remove({ key: "token" });
    } else {
      await Preferences.set({ key: "token", value: token as string });
    }
  }
  function setNotificationToken(token: string): void {
    pushNotificationToken.value = token;
  }
  function setRegistrationType(type: string): void {
    registrationType.value = type;
  }

  async function registerNotification(): Promise<void> {
    if (!pushNotificationToken.value) {
      return;
    }
    const deviceInfo: DeviceInfo = await Device.getInfo();
    await useCapacitorHttp("user/device", "POST", {
      notification_token: pushNotificationToken.value,
      model: deviceInfo.model,
      platform: deviceInfo.platform,
      manufacturer: deviceInfo.manufacturer,
      name: deviceInfo.name,
    });
  }
  async function me(): Promise<void> {
    const layoutStore = useLayoutStore();
    const result: HttpResponse = await useCapacitorHttp("auth/me", "GET");
    user.value = result.data;
    await layoutStore.switchDarkMode(user.value?.profile.dark_mode as boolean);
  }
  function waitForFirebase(): Promise<
    User | null | FirebaseUser | PromiseLike<User>
  > {
    return new Promise((resolve) => {
      if (!firebaseToken.value) {
        resolve(null);
      }
      if (user.value) {
        resolve(user.value);
      }
      FirebaseAuthentication.addListener("authStateChange", async (change) => {
        if (!change.user) {
          resolve(null);
        } else {
          try {
            await FirebaseAnalytics.setUserId({ userId: change.user.uid });
            const tokenResult = await FirebaseAuthentication.getIdToken();
            await setFirebaseToken(tokenResult.token);
            setRegistrationType(change.user.providerData[0]?.providerId);
            await Promise.all([me(), registerNotification()]);
            resolve(change.user);
          } catch (e: any) {
            resolve(null);
          }
        }
      });
    });
  }
  async function checkToken(): Promise<boolean> {
    if (user.value) {
      return true;
    }
    const { value } = await Preferences.get({ key: "token" });
    return !!value;
  }

  const isLogged = computed(() => !!user.value);

  const token = computed(() => firebaseToken.value);

  return {
    user,
    pushNotificationToken,
    firebaseToken,
    registrationType,
    setUser,
    initToken,
    logout,
    setFirebaseToken,
    setNotificationToken,
    setRegistrationType,
    registerNotification,
    me,
    waitForFirebase,
    checkToken,
    isLogged,
    token,
  };
});

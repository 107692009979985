import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import authRoutes from "@/router/auth";
import newsRoutes from "@/router/news";
import marketRoutes from "@/router/market";
import watchlistRoutes from "@/router/watchlist";
import { useAuthStore } from "@/stores/useAuthStore";
import { useNoticeStore } from "@/stores/useNoticeStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/magazine",
    name: "homepage",
  },
  {
    path: "/search",
    component: () => import("@/views/search/SearchList.vue"),
  },
  {
    path: "/price-alert",
    component: () => import("@/views/asset/PriceAlert.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/page/:slug",
    component: () => import("@/views/PageView.vue"),
  },
  {
    path: "/app",
    component: () => import("@/views/MobileAppView.vue"),
  },
  {
    path: "/contact",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/ad-free",
    component: () => import("@/views/package/AdFree.vue"),
  },
  {
    path: "/analysis/:id",
    component: () => import("@/views/analysis/AnalysisItem.vue"),
  },
  {
    path: "/package/vip",
    component: () => import("@/views/package/VipPackage.vue"),
  },
  {
    path: "/package/rsi",
    component: () => import("@/views/package/RsiSledgeEbook.vue"),
  },
  ...authRoutes,
  ...newsRoutes,
  ...marketRoutes,
  ...watchlistRoutes,
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  await authStore.initToken();
  const noticeStore = useNoticeStore();
  if (to.matched.some((record) => record.meta?.requiresAuth)) {
    const token = authStore.token;
    if (!token) {
      noticeStore.setNotice(
        "Please login or create account to continue.",
        "warning",
      );
      next({
        path: "/auth/login",
      });
    } else {
      next();
    }
  } else next();
});

export default router;

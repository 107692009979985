import { RouteRecordRaw } from "vue-router";

const newsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/magazine",
    component: () => import("@/views/news/NewsList.vue"),
  },
  {
    path: "/magazine/:category-news",
    component: () => import("@/views/news/NewsList.vue"),
  },
  {
    path: "/magazine/tag/:tag",
    component: () => import("@/views/news/NewsList.vue"),
  },
  {
    path: "/magazine/:slug",
    component: () => import("@/views/news/NewsItem.vue"),
  },
];

export default newsRoutes;

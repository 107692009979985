import { defineStore } from "pinia";
import { ref } from "vue";

export const useLoadingStore = defineStore("loading", () => {
  const loading = ref(false);
  const configActivated = ref(false);

  function setLoading() {
    loading.value = !loading.value;
  }
  function setActivated(value: boolean) {
    configActivated.value = value;
  }

  return { loading, configActivated, setLoading, setActivated };
});

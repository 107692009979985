import { RouteRecordRaw } from "vue-router";

const marketRoutes: Array<RouteRecordRaw> = [
  {
    path: "/market",
    component: () => import("@/views/asset/MarketList.vue"),
  },
  {
    path: "/forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: {
      section: "crypto",
      exchange: null,
    },
  },
  {
    path: "/stock-forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: {
      section: "stock",
      exchange: "us",
    },
  },
  {
    path: "/forex-forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: {
      section: "forex",
      exchange: null,
    },
  },
  {
    path: "/commodity-forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: {
      section: "commodity",
      exchange: null,
    },
  },
  {
    path: "/fund-forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: {
      section: "fund",
      exchange: null,
    },
  },
  {
    path: "/:exchange-stock-forecast/:slug-prediction",
    component: () => import("@/views/asset/AssetWrapper.vue"),
    props: (route) => ({ section: "stock", exchange: route.params.exchange }),
  },
];

export default marketRoutes;

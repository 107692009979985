<template>
  <div></div>
</template>
<script setup lang="ts">
import { useAuthStore } from "@/stores/useAuthStore";
import { useLayoutStore } from "@/stores/useLayoutStore";
import { useCrashlytics } from "@/composables/useCrashlytics";

const authStore = useAuthStore();
const layoutStore = useLayoutStore();

try {
  await layoutStore.initDarkMode();
  await authStore.waitForFirebase();
} catch (e: any) {
  useCrashlytics(e.message);
}
</script>

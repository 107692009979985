export enum Package {
  ADFREE = "adfree",
  VIP_ANALYSIS_MONTHLY = "analysis_monthly",
  VIP_ANALYSIS_YEARLY = "analysis_yearly",
  RSI_SAMPLE = "rsi_sample",
  RSI_FULL = "rsi_full",
}

interface PackageDetails {
  priceMicros: number;
  currency: string;
}

type EnumDictionary = Record<Package, PackageDetails>;

export const packages: EnumDictionary = {
  [Package.ADFREE]: {
    priceMicros: 2.99 * 1000000,
    currency: "USD",
  },
  [Package.VIP_ANALYSIS_MONTHLY]: {
    priceMicros: 6.99 * 1000000,
    currency: "USD",
  },
  [Package.VIP_ANALYSIS_YEARLY]: {
    priceMicros: 59.99 * 1000000,
    currency: "USD",
  },
  [Package.RSI_SAMPLE]: {
    priceMicros: 0.99 * 1000000,
    currency: "USD",
  },
  [Package.RSI_FULL]: {
    priceMicros: 99.99 * 1000000,
    currency: "USD",
  },
};

export const getPackage = (packageId: Package): PackageDetails => {
  return packages[packageId];
};

<template>
  <Teleport to="body">
    <fwb-modal
      v-if="isShowModal"
      :not-escapable="notEscapable"
      :persistent="notEscapable"
      modal-classes="dark:bg-gray-800 dark:border-gray-700 bg-white dark:border"
      body-classes="p-0"
      @close="closeModal"
    >
      <template #body>
        <div class="flex flex-col items-center justify-center">
          <div class="w-full md:mt-0 sm:max-w-lg xl:p-0">
            <div class="space-y-4 md:space-y-6 p-6 md:pb-6 md:p-0">
              <h1
                class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
              >
                {{ t("user.register.title") }}
              </h1>
              <UserSocialLogin
                @open-login-with-email="hideForm = false"
              ></UserSocialLogin>

              <div :class="{ hidden: hideForm, block: !hideForm }">
                <FormKit
                  id="reset-password"
                  type="form"
                  :submit-label="$t('form.submit_button')"
                  :value="values"
                  @submit="register"
                >
                  <FormKit
                    type="text"
                    :label="$t('user.register.email_input')"
                    name="email"
                    validation="required|email"
                  ></FormKit>
                  <FormKit
                    type="password"
                    :label="$t('user.register.password_input')"
                    name="password"
                    validation="required|length:6"
                  ></FormKit>
                  <FormKit
                    type="checkbox"
                    name="agreement"
                    validation="accepted"
                    validation-visibility="dirty"
                    decorator-icon="check"
                  >
                    <template #label>
                      <span class="dark:text-white">{{
                        t("user.register.accept_terms")
                      }}</span>
                      <a
                        class="ml-1 font-medium text-green-600 hover:underline dark:text-green-500"
                        href="#"
                      >
                        {{ t("user.register.term_and_conditions_url") }}</a
                      >
                    </template>
                  </FormKit>
                </FormKit>
              </div>
              <div>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  {{ t("user.register.already_have_button") }}
                  <a
                    href="#"
                    class="hover:cursor-pointer font-medium text-green-600 hover:underline dark:text-green-500"
                    @click.prevent="switchToLogin"
                  >
                    {{ t("user.register.login_button") }}</a
                  >
                </p>
              </div>
              <div
                v-if="notEscapable"
                class="hover:cursor-pointer font-medium text-green-600 hover:underline dark:text-green-500"
              >
                <fwb-button
                  color="light"
                  class="hover:cursor-pointer inline-flex items-center"
                  @click.prevent="goBack"
                >
                  <template #prefix>
                    <svg
                      class="w-4 h-4 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 12h14M5 12l4-4m-4 4 4 4"
                      ></path>
                    </svg>
                  </template>
                  Go Back
                </fwb-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </fwb-modal>
  </Teleport>
</template>

<script setup lang="ts">
import UserSocialLogin from "@/components/user/UserSocialLogin.vue";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { useModalStore } from "@/stores/useModalStore";
import FwbModal from "@/components/layout/FwbModal.vue";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { useCapacitorHttp } from "@/composables/useCapacitorHttp";
import { useNoticeStore } from "@/stores/useNoticeStore";
import { FwbButton } from "flowbite-vue";
import { useIonRouter } from "@ionic/vue";
const { t } = useI18n();
const hideForm = ref(true);

const values = ref({
  email: "",
  password: "",
});
const store = useNoticeStore();
const modalStore = useModalStore();
const router = useIonRouter();

modalStore.addModal({ type: "userRegister", isOpen: false });

const notEscapable = computed(() => {
  return modalStore.isEscapable("userRegister");
});

const isShowModal = computed(() => {
  return modalStore.isOpen("userRegister");
});
const closeModal = () => {
  modalStore.closeModal("userRegister");
};
const switchToLogin = () => {
  modalStore.closeModal("userRegister");
  modalStore.openModal("userLogin", notEscapable.value);
};

const goBack = () => {
  modalStore.closeModal("userRegister");
  return router.canGoBack() ? router.back() : router.push("/");
};

const register = async (values: any) => {
  try {
    await FirebaseAuthentication.createUserWithEmailAndPassword({
      email: values.email,
      password: values.password,
    });
    await FirebaseAuthentication.sendEmailVerification();
    await useCapacitorHttp("auth/register", "post");

    store.setNotice(t("user.register.notice_success"));

    modalStore.closeModal("userRegister");
  } catch (error: any) {
    if (error.code == "auth/email-already-in-use") {
      store.setNotice(t("user.register.notice_email_in_use"), "danger");
    } else {
      store.setNotice(t("user.register.notice_error"), "danger");
    }
  }
};
</script>

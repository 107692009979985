import { defineStore } from "pinia";

interface Modal {
  type: string;
  isOpen: boolean;
  notEscapable?: boolean;
  additionalData?: object;
}
export const useModalStore = defineStore("modal", {
  state: (): { modals: Array<Modal> } => ({
    modals: [],
  }),
  actions: {
    addModal(modal: Modal): void {
      this.modals.push(modal);
      this.modals = [
        ...new Map(this.modals.map((item) => [item["type"], item])).values(),
      ];
    },
    openModal(type: string, notEscapable = false): void {
      this.modals = this.modals.map(function (modal: Modal) {
        if (modal.type === type) {
          modal.isOpen = true;
          modal.notEscapable = notEscapable;
        }
        return modal;
      });
    },
    closeModal(type: string): void {
      this.modals = this.modals.map(function (modal: Modal) {
        if (modal.type === type) {
          modal.isOpen = false;
        }
        return modal;
      });
    },
    setAdditionalData(type: string, data: object): void {
      this.modals = this.modals.map(function (modal: Modal) {
        if (modal.type === type) {
          modal.additionalData = data;
        }
        return modal;
      });
    },
  },
  getters: {
    isOpen: (state): ((type: string) => any) => {
      return (type: string) => {
        const modal = state.modals.find((modal) => modal.type === type);
        return modal?.isOpen;
      };
    },
    additionalData: (state): ((type: string) => any) => {
      return (type: string) => {
        const modal = state.modals.find((modal) => modal.type === type);
        return modal?.additionalData;
      };
    },
    isEscapable: (state): ((type: string) => any) => {
      return (type: string) => {
        const modal = state.modals.find((modal) => modal.type === type);
        return !!modal?.notEscapable;
      };
    },
  },
});

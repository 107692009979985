import { createApp } from "vue";
import { i18n } from "@/i18n";
import { createPinia } from "pinia";
import { register } from "swiper/element/bundle";
import { Capacitor } from "@capacitor/core";
import { plugin, defaultConfig } from "@formkit/vue";
import config from "@/formkit.config";

import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import { initializeApp } from "firebase/app";
import * as Sentry from "@sentry/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";

/* Theme variables */
import "./theme/variables.css";
import "@vueform/multiselect/themes/tailwind.css";
import "./theme/style.css";

if (Capacitor.getPlatform() === "web") {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_API_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  };
  initializeApp(firebaseConfig);
}
register();

const pinia = createPinia();
const app = createApp(App);
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://b1dc77cb16319e7c3f4063b0c0c5bc4c@o421638.ingest.sentry.io/4506710528425984",
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.walletinvestor\.app/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .use(IonicVue)
  .use(router)
  .use(pinia)
  .use(plugin, defaultConfig(config))
  .use(i18n);

router.isReady().then(() => {
  app.mount("#app");
});

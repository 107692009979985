import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { useAuthStore } from "@/stores/useAuthStore";
import { useNoticeStore } from "@/stores/useNoticeStore";
import { useCapacitorHttp } from "@/composables/useCapacitorHttp";
import { i18n } from "@/i18n";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useCrashlytics } from "@/composables/useCrashlytics";

export function useUserLogin() {
  const store = useAuthStore();
  const noticeStore = useNoticeStore();
  const loadingStore = useLoadingStore();
  const afterLogin = async (social = false) => {
    try {
      const tokenResult = await FirebaseAuthentication.getIdToken();
      await store.setFirebaseToken(tokenResult.token);
      if (social) {
        await useCapacitorHttp("auth/register", "post");
      }
      await Promise.all([
        store.me(),
        useCapacitorHttp("auth/ping", "post"),
        store.registerNotification(),
      ]);

      noticeStore.setNotice(i18n.global.t("user.login.login_success"));
    } catch (error) {
      noticeStore.setNotice(i18n.global.t("user.login.login_error"), "danger");
    }
  };

  const loginWithGoogle = async () => {
    loadingStore.setLoading();
    try {
      await FirebaseAuthentication.signInWithGoogle();
      await afterLogin(true);
    } catch (e: any) {
      useCrashlytics(e.message);
    }
    loadingStore.setLoading();
  };

  const loginWithApple = async () => {
    loadingStore.setLoading();
    try {
      await FirebaseAuthentication.signInWithApple();
      await afterLogin(true);
    } catch (e: any) {
      useCrashlytics(e.message);
    }
    loadingStore.setLoading();
  };

  const loginWithFacebook = async () => {
    loadingStore.setLoading();
    try {
      await FirebaseAuthentication.signInWithFacebook();
      await afterLogin(true);
    } catch (e: any) {
      useCrashlytics(e.message);
    }
    loadingStore.setLoading();
  };

  const loginWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<boolean> => {
    loadingStore.setLoading();
    try {
      const store = useAuthStore();
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email: email,
        password: password,
      });
      store.setRegistrationType(result.user?.providerId as string);
      await afterLogin();
      loadingStore.setLoading();
      return true;
    } catch (error: any) {
      if (error.code === "auth/invalid-login-credentials") {
        noticeStore.setNotice(
          i18n.global.t("user.login.firebase_login_invalid_password"),
          "danger",
        );
      } else {
        noticeStore.setNotice(
          i18n.global.t("user.login.firebase_login_error"),
          "danger",
        );
      }
    }
    loadingStore.setLoading();
    return false;
  };

  return {
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithApple,
    loginWithFacebook,
  };
}

<template>
  <Teleport to="body">
    <fwb-modal
      v-if="isShowModal"
      :not-escapable="notEscapable"
      :persistent="notEscapable"
      @close="closeModal"
    >
      <template #header>
        <div class="flex items-center text-lg dark:text-gray-300">
          {{ $t("update_app.title") }}
        </div>
      </template>
      <template #body>
        <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          {{ $t("update_app.subtitle") }}
        </p>
      </template>
      <template #footer>
        <div class="flex justify-center">
          <fwb-button color="green" @click="update">
            {{ $t("update_app.update_now") }}
          </fwb-button>
        </div>
      </template>
    </fwb-modal>
  </Teleport>
</template>

<script setup lang="ts">
import FwbModal from "@/components/layout/FwbModal.vue";
import { FwbButton } from "flowbite-vue";
import { computed } from "vue";
import { useModalStore } from "@/stores/useModalStore";
import { Capacitor } from "@capacitor/core";
import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";
const modalStore = useModalStore();

const props = defineProps({
  notEscapable: { type: Boolean, default: false },
});

modalStore.addModal({ type: "updateApp", isOpen: false });

const isShowModal = computed(() => {
  return modalStore.isOpen("updateApp");
});
const closeModal = () => {
  if (props.notEscapable) {
    return;
  }
  modalStore.closeModal("updateApp");
};
const startUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (result.flexibleUpdateAllowed) {
    await AppUpdate.startFlexibleUpdate();
  } else if (result.immediateUpdateAllowed) {
    await AppUpdate.performImmediateUpdate();
  } else {
    await AppUpdate.openAppStore();
  }
};
const update = async () => {
  if (Capacitor.getPlatform() === "ios") {
    await AppUpdate.openAppStore();
  } else {
    await startUpdate();
  }
};
</script>

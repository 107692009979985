import { HttpResponse } from "@capacitor/core";
import { useCapacitorHttp } from "@/composables/useCapacitorHttp";
import { useCrashlytics } from "@/composables/useCrashlytics";

export const billingPortal = async (): Promise<void> => {
  try {
    const response: HttpResponse = await useCapacitorHttp(
      "stripe/billing-portal",
      "GET",
    );
    window.location.href = response.data.url;
  } catch (error: any) {
    useCrashlytics(error.message);
  }
};

import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  AdmobConsentStatus,
  BannerAdPluginEvents,
  AdMobBannerSize,
} from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { useInAppPurchaseStore } from "@/stores/useInAppPurchaseStore";
import { Preferences } from "@capacitor/preferences";
import { useLayoutStore } from "@/stores/useLayoutStore";
import { Package } from "@/utils/packages";
import { useCrashlytics } from "@/composables/useCrashlytics";

export function useAdmob() {
  const initAdmob = async (): Promise<void> => {
    try {
      await AdMob.initialize();

      const [trackingInfo, consentInfo] = await Promise.all([
        AdMob.trackingAuthorizationStatus(),
        AdMob.requestConsentInfo(),
      ]);

      if (trackingInfo.status === "notDetermined") {
        /**
         * If you want to explain TrackingAuthorization before showing the iOS dialog,
         * you can show the modal here.
         * ex)
         * const modal = await this.modalCtrl.create({
         *   component: RequestTrackingPage,
         * });
         * await modal.present();
         * await modal.onDidDismiss();  // Wait for close modal
         **/

        await AdMob.requestTrackingAuthorization();
      }

      const authorizationStatus = await AdMob.trackingAuthorizationStatus();
      if (
        authorizationStatus.status === "authorized" &&
        consentInfo.isConsentFormAvailable &&
        consentInfo.status === AdmobConsentStatus.REQUIRED
      ) {
        await AdMob.showConsentForm();
      }
    } catch (e: any) {
      useCrashlytics(e.message);
    }
  };

  AdMob.addListener(
    BannerAdPluginEvents.SizeChanged,
    (size: AdMobBannerSize) => {
      const layoutStore = useLayoutStore();
      layoutStore.setBannerHeight(size.height);
    },
  );

  const initStickyBanner = async (): Promise<void> => {
    try {
      const purchaseStore = useInAppPurchaseStore();
      if (purchaseStore.getPurchasedSubscriptionById(Package.ADFREE)) {
        return;
      }
      const options: BannerAdOptions = {
        adId:
          Capacitor.getPlatform() === "ios"
            ? "ca-app-pub-5656572056344676/1572277017"
            : "ca-app-pub-5656572056344676/9805990254",
        adSize: BannerAdSize.ADAPTIVE_BANNER,
        position: BannerAdPosition.BOTTOM_CENTER,
        margin: 55,
      };
      await AdMob.showBanner(options);
    } catch (e: any) {
      useCrashlytics(e.message);
    }
  };

  const hideStickyBanner = async (): Promise<void> => {
    console.log("hide sticky");

    if (Capacitor.getPlatform() === "web") {
      return;
    }
    await AdMob.hideBanner();
  };

  const showStickyBanner = async (): Promise<void> => {
    console.log("show sticky");
    if (Capacitor.getPlatform() === "web") {
      return;
    }
    await AdMob.resumeBanner();
  };

  const initFullBanner = async (): Promise<void> => {
    const purchaseStore = useInAppPurchaseStore();
    if (purchaseStore.getPurchasedSubscriptionById(Package.ADFREE)) {
      return;
    }
    const { value } = await Preferences.get({ key: "full_banner_show_at" });
    const date = new Date().getTime();
    const bannerLastShowDate = parseInt(value ?? "0");
    const intervalOfShow = 3 * 60 * 60 * 1000;
    if (value && bannerLastShowDate + intervalOfShow > date) {
      return;
    }
    await Preferences.set({
      key: "full_banner_show_at",
      value: String(new Date().getTime()),
    });
    const options: BannerAdOptions = {
      adId:
        Capacitor.getPlatform() === "ios"
          ? "ca-app-pub-5656572056344676/9744356982"
          : "ca-app-pub-5656572056344676/2606418781",
    };
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  };

  return {
    initAdmob,
    initStickyBanner,
    hideStickyBanner,
    showStickyBanner,
    initFullBanner,
  };
}
